@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  body {
    background-color: rgb(255, 255, 255);
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    transition: background-color 0.3s ease-in-out;
  }
  
  body.menu-open {
    background-color: black;
  }
  
  body.menu-open .top-bar,
  body.menu-open .column,
  body.menu-open .center-column {
    background-color: black; /* Target background color when menu is open */
  }
  
  .app-container {
    width: 100%;
    margin: 0 auto;
  }
  
  .top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 10px 20px;
    min-height: 50px;
    z-index: 1000;
    transition: background-color 0.3s ease-in-out;
  }
  
  .hamburger {
    cursor: pointer;
    margin-right: 10px;
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    margin-left: auto;
  }
  
  .hamburger span {
    display: block;
    width: 50%;
    height: 2px;
    background-color: black;
    position: absolute;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  /* Top line parts */
  .hamburger span:nth-child(1) {
    left: 0;
    top: 4px;
  }
  
  .hamburger span:nth-child(2) {
    right: 0;
    top: 4px;
  }
  
  /* Bottom line parts */
  .hamburger span:nth-child(3) {
    left: 0;
    top: 14px;
  }
  
  .hamburger span:nth-child(4) {
    right: 0;
    top: 14px;
  }
  
  /* Transformations for open state */
  .hamburger.open span:nth-child(1) {
    transform: rotate(45deg);
    left: 2.5px;
  }
  
  .hamburger.open span:nth-child(4) {
    transform: rotate(45deg);
    right: 2.5px;
  }
  
  .hamburger.open span:nth-child(2) {
    transform: rotate(-45deg);
    right: 2.5px;
  }
  
  .hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
    left: 2.5px;
  }
  
  .hamburger.open span {
    background-color: white;
  }
  
  .topbar-menu-title {
    color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0);
    padding: 10px 10px;
    margin-left: 10px;
    border: none;
    cursor: pointer;
    font-family: 'STM_Medium', Arial, sans-serif;
    font-size: clamp(18px, 2.5vw, 24px);
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .topbar-menu-title::before {
    content: '→';
    position: absolute;
    left: -15px;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateX(-5px);
  }
  
  .topbar-menu-title:hover::before {
    opacity: 1;
    transform: translateX(0);
  }
  
  .topbar-menu-title:hover {
    transform: translateX(10px);
  }
  
  
  .topbar-menu-buttons {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px 10px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid;
    border-color: white;
    font-size: clamp(20px, 2.5vw, 28px);
    opacity: 0; /* Initial opacity */
    transition: opacity 0.6s ease-in; /* Transition for opacity */
  }
  
  .topbar-menu-buttons-last {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px 10px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: white;
    font-size: clamp(20px, 2.5vw, 28px);
    opacity: 0; /* Initial opacity */
    transition: opacity 0.7s ease-in-out; /* Transition for opacity */
  }
  
  .topbar-menu-buttons.open, .topbar-menu-buttons-last.open{
    opacity: 1;
  }
  
  .topbar-menu-buttons:hover {
    color: rgb(0, 0, 0);
    background-color: white;
  }
  
  .topbar-menu-buttons-last:hover {
    color: rgb(0, 0, 0);
    background-color: white;
  }
  
  .line {
    height: 2px;
    background-color: rgb(255, 255, 255);
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
    margin-top: 0px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: #000000;
    z-index: 10;
  }
  
  .column {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0px;
    background-color: white;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  
  .contact-container{
    align-self: center;
    margin-left: 40px;
    margin-right: 40px;
  }

  .contact-container.open{
    display: none;
  }

  .contact-text-container{
    max-width:600px;
  }

  .contact-text{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .contact-icon-container{
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }

  .contact-icon{
    height:50px;
    width:50px;
    padding-right: 15px
  }

  .contact-icon:hover{
    transform: translateY(-10px);
    transition: 0.4s
  }

  .contact-icon-container.open{
    display: none;
  }

  .substack-icon{
    height:42px;
    width:40px;
    padding-right: 15px;
    padding-top: 4px;
  }

  .substack-icon:hover{
    transform: translateY(-10px);
    transition: 0.4s
  }

  @media (max-width: 768px) {
    .contact-text{
      font-size: 14px;
    }
    .contact-icon{
      height: 40px;
      width: 40px;
    }
    .substack-icon{
      height: 32px;
      width: 34px;
    }
  }