@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body.menu-open .top-bar,
body.menu-open .column,
body.menu-open .center-column {
  background-color: black; /* Target background color when menu is open */
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding: 10px 20px;
  min-height: 50px;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;
}

.hamburger {
  cursor: pointer;
  margin-right: 20px;
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  margin-left: auto;
}

.hamburger span {
  display: block;
  width: 50%;
  height: 2px;
  background-color: black;
  position: absolute;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* Top line parts */
.hamburger span:nth-child(1) {
  left: 0;
  top: 4px;
}

.hamburger span:nth-child(2) {
  right: 0;
  top: 4px;
}

/* Bottom line parts */
.hamburger span:nth-child(3) {
  left: 0;
  top: 14px;
}

.hamburger span:nth-child(4) {
  right: 0;
  top: 14px;
}

/* Transformations for open state */
.hamburger.open span:nth-child(1) {
  transform: rotate(45deg);
  left: 2.5px;
}

.hamburger.open span:nth-child(4) {
  transform: rotate(45deg);
  right: 2.5px;
}

.hamburger.open span:nth-child(2) {
  transform: rotate(-45deg);
  right: 2.5px;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
  left: 2.5px;
}

.hamburger.open span {
  background-color: white;
}

.topbar-menu-title {
  color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0);
  padding: 10px 10px;
  margin-left: 20px;
  border: none;
  cursor: pointer;
  font-family: 'STM_Medium', Arial, sans-serif;
  font-size: clamp(18px, 2.5vw, 24px);
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  position: relative;
  transition: transform 0.3s ease;
}

.topbar-menu-title.hide {
  display: none;
}

.topbar-menu-title::before {
  content: '→';
  position: absolute;
  left: -15px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateX(-5px);
}

.topbar-menu-title:hover::before {
  opacity: 1;
  transform: translateX(0);
}

.topbar-menu-title:hover {
  transform: translateX(10px);
}

.topbar-menu-buttons {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  width: 100%;
  padding: 10px 10px;
  border: none;
  text-align: left;
  cursor: pointer; 
  border-top: 1px solid;
  border-color: white;
  font-size: clamp(20px, 2.5vw, 28px);
  opacity: 0; /* Initial opacity */
  transition: opacity 0.6s ease-in; /* Transition for opacity */
}

.topbar-menu-buttons-last {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  width: 100%;
  padding: 10px 10px;
  border: none;
  text-align: left;
  cursor: pointer;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: white;
  font-size: clamp(20px, 2.5vw, 28px);
  opacity: 0; /* Initial opacity */
  transition: opacity 0.7s ease-in-out; /* Transition for opacity */
}

.topbar-menu-buttons.open,
.topbar-menu-buttons-last.open {
  opacity: 1;
}

.topbar-menu-buttons:hover {
  color: rgb(0, 0, 0);
  background-color: white;
}

.topbar-menu-buttons-last:hover {
  color: rgb(0, 0, 0);
  background-color: white;
}

.line {
  height: 2px;
  background-color: rgb(255, 255, 255);
  margin-left: 40px;
  margin-right: 40px;
  width: calc(100% - 80px);
  margin-top: 0px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background-color: #000000;
  z-index: 10;
}

.column {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 0px;
  background-color: white;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.arrow-left {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  color: #000000;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  outline: none;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.arrow-left::before {
  content: '←'; /* Unicode character for a left arrow */
  display: inline-block;
  margin-right: 5px; /* Space between arrow and text */
  transition: transform 0.3s ease-in-out; /* Transition for movement */
}

.arrow-left:hover::before {
  transform: translateX(-5px); /* Move arrow left on hover */
}

.arrow-left.show {
  opacity: 0;
}

.arrow-left.hide {
  display: none;
}




/* Page CSS */




.page-content {
  padding-top: 60px; /* Space for the fixed top bar */
  box-sizing: border-box;
  overflow-y: auto; /* Allow vertical scrolling */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.center-page{
  align-self: center;

}

.content-title-container {
  margin-top: 20px; /* Adjust to fine-tune space below the top bar */
  width: 100%; /* Ensure it doesn't exceed parent width */
  max-width: 100%; /* Ensure max-width is 100% for responsiveness */
  min-width: unset; /* Remove min-width to prevent overflow */
}

.content-title {
  font-size: 30px;
  color: black;
  padding-bottom: 20px;
}

.content-title.open{
  display: none;
}

.content-subtitle {
  font-style: italic;
  padding-bottom: 20px;
}

.content-subtitle.open{
  display: none;
}

.content-image {
  width: 100%; /* Make the image take the full width of its container */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any inline-block or inline gaps */
  margin: 0 auto; /* Center the image horizontally if block */
  border-radius: 5px;
}

.content-image.open{
  display: none;
}

.content-text-container{
  width: 100%; /* Make the image take the full width of its container */
  max-width: 600px; /* Maximum width for large screens (desktop) */
  min-width: 200px; /* Minimum width for small screens (mobile) */
  padding-top: 40px;
  padding-bottom: 40px;
}

.content-text-container.open{
  display: none;
}

.content-text{
  padding-left: 15px;
  padding-right: 15px;
}
/* Footer.css */

.footer {
  align-self: center;
  width: 100%; /* Ensure it fits within the viewport */
  max-width: 100%;
  background-color: #ffffff; /* Dark background color */
  color: #000000; /* White text color */
  padding: 40px 0; /* Padding around the footer */
  text-align: center; /* Center align the text */
}

.footer.open{
  display: none;
}

.footer-buttons {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 10px; /* Spacing between buttons */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  list-style: none; /* Remove list styling */
}

.footer-button {
  background: none; /* No background */
  border: none; /* No border */
  color: #000000; /* Text color */
  font-size: 16px; /* Font size for buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: color 0.3s; /* Transition for color change on hover */
  text-decoration: underline; /* Underline to mimic link */
}

.footer-button:hover {
  color: #bdbdbd; /* Slightly lighter color on hover */
}

.footer-copyright {
  margin-top: 10px; /* Space between buttons and copyright */
  font-size: 14px; /* Smaller font size for copyright */
  color: #000000; /* Lighter color for copyright text */
}

@media (max-width: 750px) {
  .footer-copyright{
    font-size:12px;
  }
  .footer-button{
    font-size: 14px;
  }
  .footer-buttons{
    gap: 5px;
  }
}

/*large css */

.content-title-container-large {
  margin-top: 20px; /* Adjust to fine-tune space below the top bar */
}

.content-title-large {
  font-size: 30px;
  color: black;
  padding-bottom: 20px;
  transition: opacity var(--transition-duration-in, 0.3s) var(--transition-timing-function-in, ease-in), visibility var(--transition-duration-in, 0.3s) var(--transition-timing-function-in, ease-in);
}

.content-title-large.open {
  opacity: 0; /* Fade out */
  visibility: hidden; /* Hide without removing from layout */
  transition: opacity var(--transition-duration-out, 0.1s) var(--transition-timing-function-out, ease-out), visibility var(--transition-duration-out, 0.1s) var(--transition-timing-function-out, ease-out);
}

.content-subtitle-large {
  font-style: italic;
  transition: opacity var(--transition-duration-in, 0.3s) var(--transition-timing-function-in, ease-in), visibility var(--transition-duration-in, 0.3s) var(--transition-timing-function-in, ease-in);
}

.content-subtitle-large.open {
  opacity: 0; /* Fade out */
  visibility: hidden; /* Hide without removing from layout */
  transition: opacity var(--transition-duration-out, 0.1s) var(--transition-timing-function-out, ease-out), visibility var(--transition-duration-out, 0.1s) var(--transition-timing-function-out, ease-out);
}

.content-row-large {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Space out the image and text equally */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Add space between the image and text */
  padding-top: 20px;
}

.image-container {
  display: flex; /* Use flexbox to align children */
  justify-content: center; /* Center the image horizontally (optional) */
  align-items: flex-start; /* Align the image to the top */
  height: 100%; /* Make the container take up the full height of its parent (if needed) */
  margin-top: 30px;
}

.content-image-large {
  flex: 1; /* Allow the image to shrink only if absolutely necessary */
  width: 100%; /* Ensure the image does not exceed its space */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any inline-block or inline gaps */
  margin: 0;
  border-radius: 5px;
  max-width: 600px;
  opacity: 1; /* Fully visible by default */
  visibility: visible; /* Make it visible by default */
  transition: opacity var(--transition-duration-in, 0.4s) var(--transition-timing-function-in, ease-in), visibility var(--transition-duration-in, 0.4s) var(--transition-timing-function-in, ease-in);
}

.content-image-large.open {
  opacity: 0; /* Fade out */
  visibility: hidden; /* Hide without removing from layout */
  transition: opacity var(--transition-duration-out, 0.1s) var(--transition-timing-function-out, ease-out), visibility var(--transition-duration-out, 0.1s) var(--transition-timing-function-out, ease-out);
}

.content-text-container-large {
  flex: 1; /* Text will take up one part of the space */
  padding: 0 20px; /* Add padding to the text container */
  max-width: 50%; /* Ensure the text and image fit within the container */
  display: flex;
  flex-direction: column;
  transition: opacity var(--transition-duration-in, 0.3s) var(--transition-timing-function-in, ease-in), visibility var(--transition-duration-in, 0.3s) var(--transition-timing-function-in, ease-in);
}

.dnb-text-container-large.open {
  opacity: 0; /* Fade out */
  visibility: hidden; /* Hide without removing from layout */
  transition: opacity var(--transition-duration-out, 0.1s) var(--transition-timing-function-out, ease-out), visibility var(--transition-duration-out, 0.1s) var(--transition-timing-function-out, ease-out);
}

.content-text-large {
  margin: 0; /* Remove default margins */
  font-size: 16px; /* Set a comfortable reading size */
  line-height: 1.5; /* Improve readability */
  padding-bottom: 10px;
}

.center-link {
  display: flex;
  justify-content: center;
}

.footer-large {
  display: block;
  align-self: center;
  width: 100%; /* Ensure it fits within the viewport */
  max-width: 100%;
  background-color: #ffffff; /* Background color */
  color: #000000; /* Text color */
  padding: 40px 0; /* Padding around the footer */
  text-align: center; /* Center align the text */
  opacity: 1; /* Fully visible by default */
  visibility: visible; /* Make it visible by default */
  transition: opacity var(--transition-duration-in, 0.7s) var(--transition-timing-function-in, ease-in), visibility var(--transition-duration-in, 0.7s) var(--transition-timing-function-in, ease-in);
}

.footer-large.open {
  opacity: 0; /* Fade out */
  visibility: hidden; /* Hide without removing from layout */
  transition: opacity var(--transition-duration-out, 0.01s) var(--transition-timing-function-out, ease-out), visibility var(--transition-duration-out, 0.01s) var(--transition-timing-function-out, ease-out);
}

.footer-buttons-large {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  gap: 10px; /* Spacing between buttons */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  list-style: none; /* Remove list styling */
}

.footer-button-large {
  background: none; /* No background */
  border: none; /* No border */
  color: #000000; /* Text color */
  font-size: 16px; /* Font size for buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: color 0.3s; /* Transition for color change on hover */
  text-decoration: underline; /* Underline to mimic link */
}

.footer-button-large:hover {
  color: #bdbdbd; /* Slightly lighter color on hover */
}

.footer-copyright-large {
  margin-top: 10px; /* Space between buttons and copyright */
  font-size: 14px; /* Smaller font size for copyright */
  color: #000000; /* Lighter color for copyright text */
}

/* Additional styles for larger screens */
@media (min-width: 1601px) {

  .footer-large {
    width: 1600px; /* Fixed width for screens larger than 1600px */
    max-width: none; /* Prevent max-width constraint on larger screens */
  }

  .page-content{
    max-width: 1500px;
    width: 100%;
  }
}
