@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  body.menu-open {
    background-color: black;
  }
  
  body.menu-open .top-bar,
  body.menu-open .column,
  body.menu-open .center-column {
    background-color: black; /* Target background color when menu is open */
  }
  
  .top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(230, 109, 109);
    padding: 10px 20px;
    min-height: 50px;
    z-index: 1000;
    transition: background-color 0.3s ease-in-out;
  }
  
  .hamburger {
    cursor: pointer;
    margin-right: 20px;
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    margin-left: auto;
  }
  
  .hamburger span {
    display: block;
    width: 50%;
    height: 2px;
    background-color: black;
    position: absolute;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  /* Top line parts */
  .hamburger span:nth-child(1) {
    left: 0;
    top: 4px;
  }
  
  .hamburger span:nth-child(2) {
    right: 0;
    top: 4px;
  }
  
  /* Bottom line parts */
  .hamburger span:nth-child(3) {
    left: 0;
    top: 14px;
  }
  
  .hamburger span:nth-child(4) {
    right: 0;
    top: 14px;
  }
  
  /* Transformations for open state */
  .hamburger.open span:nth-child(1) {
    transform: rotate(45deg);
    left: 2.5px;
  }
  
  .hamburger.open span:nth-child(4) {
    transform: rotate(45deg);
    right: 2.5px;
  }
  
  .hamburger.open span:nth-child(2) {
    transform: rotate(-45deg);
    right: 2.5px;
  }
  
  .hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
    left: 2.5px;
  }
  
  .hamburger.open span {
    background-color: white;
  }
  
  .topbar-menu-title {
    color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0);
    padding: 10px 10px;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-family: 'STM_Medium', Arial, sans-serif;
    font-size: clamp(18px, 2.5vw, 24px);
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .topbar-menu-title::before {
    content: '→';
    position: absolute;
    left: -15px;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateX(-5px);
  }
  
  .topbar-menu-title:hover::before {
    opacity: 1;
    transform: translateX(0);
  }
  
  .topbar-menu-title:hover {
    transform: translateX(10px);
  }
  
  
  .topbar-menu-buttons {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px 10px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid;
    border-color: white;
    font-size: clamp(20px, 2.5vw, 28px);
    opacity: 0; /* Initial opacity */
    transition: opacity 0.6s ease-in; /* Transition for opacity */
  }
  
  .topbar-menu-buttons-last {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px 10px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: white;
    font-size: clamp(20px, 2.5vw, 28px);
    opacity: 0; /* Initial opacity */
    transition: opacity 0.7s ease-in-out; /* Transition for opacity */
  }
  
  .topbar-menu-buttons.open, .topbar-menu-buttons-last.open{
    opacity: 1;
  }
  
  .topbar-menu-buttons:hover {
    color: rgb(0, 0, 0);
    background-color: white;
  }
  
  .topbar-menu-buttons-last:hover {
    color: rgb(0, 0, 0);
    background-color: white;
  }
  
  .line {
    height: 2px;
    background-color: rgb(255, 255, 255);
    margin-left: 40px;
    margin-right: 40px;
    width: calc(100% - 80px);
    margin-top: 0px;
  }
  
  .line.open{
    background-color: rgb(0, 0, 0);
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: #000000;
    z-index: 10;
  }
  
  .column {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 0px;
    background-color: white;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  
  .app-container, body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  .column-container {
    display: flex;
    width: 100vw; /* Ensure this is not constrained by any parent element */
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  
  /* Page CSS */

  .experience-column {
    margin-top: 60px;
    padding: 20px;
    padding-top: 50px;
    box-sizing: border-box; /* Include padding in the width calculation */
  }
  
  .experience-column {
    flex: 1;
    background-color: #ffffff;
    height: 100vh;
    padding-left: 400px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .experience-column.open {
    background-color: #000000;
  }

  .fixed-content {
    margin-top: 95px;
    position: fixed;
    margin-left: 40px;
    width: 320px;
    height: 300px; /* Full height minus vertical padding */
    overflow-y: auto; /* Allows scrolling if content exceeds the height */
  }

  .experience-title{
    flex:1;
    display: flex;
    justify-content: left;
  }

  .experience-dates{
    flex:1;
    display: flex;
    justify-content: right;
  }

  .experience-title-line{
    display: flex;
    margin-top: 30px;
    width: calc(100% - 40px); /* Use 100% minus consistent padding */
    min-width: unset; /* Remove min-width to prevent overflow */
    flex-direction: row;
  }

  .experience-blurb{
    padding-top: 20px;
  }

  .list-item{
    padding:5px
  }

  .sections-column{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bullet-point-list{
    padding-right: 40px;
  }

  .bullet-point-list-last{
    padding-right: 40px;
    padding-bottom: 40px;
  }

  .section-title{
    font-style: italic;
    font-size: 18px;
  }

  .section-title-education{
    font-style: italic;
    font-size: 18px;
    margin-top: 50px;
  }

  .fixed-content-title{
    font-size: 18px;
    font-style: italic;
  }

  .fixed-content-text {
    padding-top: 10px;
    font-size: 16px;
  }

  .icon-container{
    display: flex;
    justify-content: left;
    padding-top: 10px;
  }

  .icon{
    height:35px;
    width:35px;
    padding-right: 15px
  }

  .icon:hover{
    transform: translateY(-10px);
    transition: 0.4s
  }

  .icon:active{
    transform: scale(0.9); /* Slightly shrink the button when active */
  }

  @media (max-width: 768px) {
    .bullet-point-list{
      font-size: 14px;
    }
    .bullet-point-list-last{
      font-size: 14px;
    }
    .experience-title{
      font-size: 14px;
    }
    .experience-dates{
      font-size: 14px;
    }
    .section-title{
      font-size: 16px;
    }
    .section-title-education{
      font-size: 16px;
    }
    .experience-blurb{
      font-size: 14px;
    }
  }

  @media (max-width: 500px) {
    .experience-title-line{
      flex-direction: column;
    }
    .experience-dates{
      justify-content: left;
      padding-top:5px;
      font-size: 14px;
    }

    .bullet-point-list{
      padding-right: 20px;
      padding-left: 18px;
    }
  
    .bullet-point-list-last{
      padding-right: 20px;
      padding-left: 18px;
    }
  }

  @media (max-width: 800px) {
    .experience-column {
      width: 100%; /* Ensure full width without causing overflow */
      padding-left: 40px; /* Adjust padding for smaller screens */
    }
  }

  @media (max-width: 800px) {
    .experience-title-line {
      width: 100%; /* Full width within the container */
      min-width: unset; /* Ensure no unnecessary restrictions */
    }
  }

  @media (max-width: 800px) {
    .fixed-content {
        display: none; /* Hide fixed content on smaller screens */
    }

}