@font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url('../public/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  .app-container {
    width: 100%;
    margin: 0 auto;
  }
  
  .top-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 10px 20px;
    min-height: 50px;
    z-index: 1000;
    transition: background-color 0.3s ease-in-out;
  }
  
  .topbar.open{
    background-color: black;
  }
  .hamburger {
    cursor: pointer;
    margin-right: 10px;
    width: 30px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    margin-left: auto;
  }
  
  .hamburger span {
    display: block;
    width: 50%;
    height: 2px;
    background-color: black;
    position: absolute;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  /* Top line parts */
  .hamburger span:nth-child(1) {
    left: 0;
    top: 4px;
  }
  
  .hamburger span:nth-child(2) {
    right: 0;
    top: 4px;
  }
  
  /* Bottom line parts */
  .hamburger span:nth-child(3) {
    left: 0;
    top: 14px;
  }
  
  .hamburger span:nth-child(4) {
    right: 0;
    top: 14px;
  }
  
  /* Transformations for open state */
  .hamburger.open span:nth-child(1) {
    transform: rotate(45deg);
    left: 2.5px;
  }
  
  .hamburger.open span:nth-child(4) {
    transform: rotate(45deg);
    right: 2.5px;
  }
  
  .hamburger.open span:nth-child(2) {
    transform: rotate(-45deg);
    right: 2.5px;
  }
  
  .hamburger.open span:nth-child(3) {
    transform: rotate(-45deg);
    left: 2.5px;
  }
  
  .hamburger.open span {
    background-color: white;
  }
  
  .topbar-menu-title {
    color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0);
    padding: 10px 10px;
    margin-left: 20px;
    border: none;
    cursor: pointer;
    font-family: 'STM_Medium', Arial, sans-serif;
    font-size: clamp(18px, 2.5vw, 24px);
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
    position: relative;
    transition: transform 0.3s ease;
  }
  
  .topbar-menu-title::before {
    content: '→';
    position: absolute;
    left: -15px;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateX(-5px);
  }
  
  .topbar-menu-title:hover::before {
    opacity: 1;
    transform: translateX(0);
  }
  
  .topbar-menu-title:hover {
    transform: translateX(10px);
  }
  
  
  .topbar-menu-buttons {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px 10px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid;
    border-color: white;
    font-size: clamp(20px, 2.5vw, 28px);
    opacity: 0; /* Initial opacity */
    transition: opacity 0.6s ease-in; /* Transition for opacity */
  }
  
  .topbar-menu-buttons-last {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
    width: 100%;
    padding: 10px 10px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgb(0, 0, 0);
    font-size: clamp(20px, 2.5vw, 28px);
    opacity: 0; /* Initial opacity */
    transition: opacity 0.7s ease-in-out; /* Transition for opacity */
  }
  
  .topbar-menu-buttons.open, .topbar-menu-buttons-last.open{
    opacity: 1;
  }
  
  .topbar-menu-buttons:hover {
    color: rgb(0, 0, 0);
    background-color: white;
  }
  
  .topbar-menu-buttons-last:hover {
    color: rgb(0, 0, 0);
    background-color: white;
  }
  
  .line {
    height: 2px;
    background-color: rgb(255, 255, 255);
    margin-left: 40px;
    margin-right: 40px;
    width: calc(100% - 80px);
    margin-top: 0px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: #000000;
    z-index: 10;
  }
  
  .column {
    margin-left: 40px;
    margin-right: 4%;
    margin-top: 0px;
    background-color: white;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column.open{
    background-color: black;
  }
  
  .center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .ceter-column.open{
    background-color: black;
  }

  .project-menu-buttons {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 10px 10px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid;
    border-color: rgb(0, 0, 0);
    font-size: clamp(20px, 2.5vw, 28px);
    opacity: 1; /* Initial opacity */
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
  
  .project-menu-buttons.open, .project-menu-buttons-last.open{
    background-color: #000000;
  }

  .project-menu-buttons-last {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    width: 100%;
    padding: 10px 10px;
    border: none;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgb(0, 0, 0);
    font-size: clamp(20px, 2.5vw, 28px);
    opacity: 1; /* Initial opacity */
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }

  .project-menu-buttons:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
  }
  
  .project-menu-buttons-last:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
  }

  .project-column {
    width: 100%;
    background-color: white;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    padding-top: 70px; /* This accounts for the fixed top bar */
    box-sizing: border-box; /* Includes padding in the element's total dimensions */
    height: 100vh /* Adjust height so it doesn't extend past the viewport */
  }
  
  .project-center-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the flex-direction */
    width: calc(100vw - 80px);
    padding-left: 40px;
    padding-right: 40px;
    overflow-y: auto; /* Allows scrolling if content overflows vertically */
  }
  
  .project-column.open {
    background-color: rgb(0, 0, 0);
  }

  .arrow-left {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent;
    border: none;
    color: #000000;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    outline: none;
    transition: display 1s ease-in-out;
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
  }
  
  .arrow-left::before {
    content: '←'; /* Unicode character for a left arrow */
    display: inline-block;
    margin-right: 5px; /* Space between arrow and text */
    transition: transform 0.3s ease-in-out; /* Transition for movement */
  }
  
  .arrow-left:hover::before {
    transform: translateX(-5px); /* Move arrow left on hover */
  }
  
  .arrow-left:hover {
    color: #000; /* Change text color on hover */
  }
  
  .arrow-left.show{
    opacity: 0;
  }

  .arrow-left.hide{
    display:none;
  }