@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.app-container {
  width: 100%;
  margin: 0 auto;
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding: 10px 20px;
  min-height: 50px;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out;
}

.hamburger {
  cursor: pointer;
  margin-right: 20px;
  width: 30px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  margin-left: auto;
}

.hamburger span {
  display: block;
  width: 50%;
  height: 2px;
  background-color: black;
  position: absolute;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* Top line parts */
.hamburger span:nth-child(1) {
  left: 0;
  top: 4px;
}

.hamburger span:nth-child(2) {
  right: 0;
  top: 4px;
}

/* Bottom line parts */
.hamburger span:nth-child(3) {
  left: 0;
  top: 14px;
}

.hamburger span:nth-child(4) {
  right: 0;
  top: 14px;
}

/* Transformations for open state */
.hamburger.open span:nth-child(1) {
  transform: rotate(45deg);
  left: 2.5px;
}

.hamburger.open span:nth-child(4) {
  transform: rotate(45deg);
  right: 2.5px;
}

.hamburger.open span:nth-child(2) {
  transform: rotate(-45deg);
  right: 2.5px;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
  left: 2.5px;
}

.hamburger.open span {
  background-color: white;
}

.topbar-menu-title {
  color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0);
  padding: 10px 10px;
  margin-left: 20px;
  border: none;
  cursor: pointer;
  font-family: 'STM_Medium', Arial, sans-serif;
  font-size: clamp(18px, 2.5vw, 24px);
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  position: relative;
  transition: transform 0.3s ease;
}

.topbar-menu-title::before {
  content: '→';
  position: absolute;
  left: -25px; /* Initial position when hidden */
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0.3s ease;
  transform: translateX(0); /* Starting transform */
}

.topbar-menu-title:hover::before {
  opacity: 1;
  left: clamp(-25px, 2.5vw, -15px); /* Move further left when visible */
  transform: translateX(0);
}

.topbar-menu-title:hover {
  transform: translateX(10px);
}

.topbar-menu-buttons {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  width: 100%;
  padding: 10px 10px;
  border: none;
  text-align: left;
  cursor: pointer;
  border-top: 1px solid;
  border-color: white;
  font-size: clamp(20px, 2.5vw, 28px);
  opacity: 0; /* Initial opacity */
  transition: opacity 0.6s ease-in; /* Transition for opacity */
}

.topbar-menu-buttons-last {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  width: 100%;
  padding: 10px 10px;
  border: none;
  text-align: left;
  cursor: pointer;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: white;
  font-size: clamp(20px, 2.5vw, 28px);
  opacity: 0; /* Initial opacity */
  transition: opacity 0.7s ease-in-out; /* Transition for opacity */
}

.topbar-menu-buttons.open, .topbar-menu-buttons-last.open{
  opacity: 1;
}

.topbar-menu-buttons:hover {
  color: rgb(0, 0, 0);
  background-color: white;
}

.topbar-menu-buttons-last:hover {
  color: rgb(0, 0, 0);
  background-color: white;
}

.line {
  height: 2px;
  background-color: rgb(255, 255, 255);
  margin-left: 40px;
  margin-right: 40px;
  width: calc(100% - 80px);
  margin-top: 0px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background-color: #000000;
  z-index: 10;
}

.column {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 0px;
  background-color: white;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.home-page-container{
  display: flex; /* Use flexbox to create the layout */
  justify-content: space-between; /* Distribute space between columns */
  align-items: stretch; /* Make sure columns stretch to the same height */
  width: 100%;
  height: 100vh;
  margin:0;
  background-color: white;
  transition: background-color 0.3s ease-in-out;
}

/*Added in order to prevent header crashing */
@media (max-width: 768px) {
  .home-page-container {
    margin-top: 60px;
  }
}

.home-page-container.open {
  background-color: black;
}

.home-text-container{
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
  flex: 5;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-self: center;
  transition: background-color 0.3s ease-in-out;
}

.home-text-container.open{
  background-color: black;
}

@media (max-width: 768px) {
  .home-text-container {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.home-text {
  font-weight:400;
  background-color: white;
  transition: background-color 0.3s ease-in-out;
}

.home-text.open{
  background-color: black;
}

.home-button{
  width: calc(100vw/4 - 50px);
  max-width: 350px;
  height: calc(100vw/4 - 50px);
  max-height: 350px;
  color: blue;
  background-color: blue;
  border-color: blue;
  border:1px solid;
  border-radius: calc(100vw/8 - 25px);
  font-size: 16px;
  margin-left:20px;
  margin-right:20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.1s ease;
}

.home-button:hover{
  background-color: yellow;
  border-color: yellow;
  color: blue;
  transition: 0.3s ease-in-out;
}

.home-button:active {
  transform: scale(0.95); /* Slightly shrink the button when active */
  background-color: #555; /* Darken the background color on click */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2) inset; /* Add inset shadow */
}

.home-button.open {
  display: none;
}

.home-button-container{
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top:40px;
  flex:4; 
}

@media (max-width: 1000px) {
    .home-button-container {
      display: none; /* Hide fixed content on smaller screens */
  }

  .home-page-container {
      width: calc(100% - 80px); /* Ensure it takes full width */
      padding-left: 40px; /* Adjust padding if necessary */
      padding-right: 40px; /* Adjust padding if necessary */
  }
}

@media (max-width: 768px) {
  .home-text{
    font-size: 14px;
  }
  .home-page-container{
    width: calc(100% - 40px); /* Ensure it takes full width */
    padding-left: 20px; /* Adjust padding if necessary */
    padding-right: 20px; /* Adjust padding if necessary */
  }
}

.name{
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
}